import {
    defineStore
} from 'pinia'


// 最新报价
export const useDetailOffer = defineStore('detailOffer', {
    state: () => {
        return {
            offerId: '', // 最新报价id
        }
    }
})

// 贸易商机
export const useNewsDetail = defineStore('newsDetail', {
    state: () => {
        return {
            newsId: '', // 贸易商及的id
        }
    }
})