import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3ae57aca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w_1200" }
const _hoisted_2 = { class: "bread" }

import LinkManVue from "@/template/LinkMan.vue"; // 引入联系人
import breadVue from "../bread.vue/bread.vue"; // 引入面包屑
import detail from "./Detail/detail.vue";

export default {
  setup(__props) {

sessionStorage.setItem("detail_current_nav", "报价详情");

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(breadVue)
    ]),
    _createElementVNode("div", null, [
      _createVNode(detail)
    ])
  ]))
}
}

}