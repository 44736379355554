import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ArrowRight } from "@element-plus/icons-vue";
import { watchEffect, ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useDetail } from "@/pinia/api/useDetail.js";
import { storeToRefs } from "pinia";

export default {
  setup(__props) {

const detailStore = useDetail();
const { company } = storeToRefs(detailStore);
const breack = ref("");
onMounted(() => {
  breack.value = sessionStorage.getItem("detail_current_nav");
});

return (_ctx, _cache) => {
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item")
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb")

  return (_openBlock(), _createBlock(_component_el_breadcrumb, { "separator-icon": _unref(ArrowRight) }, {
    default: _withCtx(() => [
      _createVNode(_component_el_breadcrumb_item, { to: { path: '/hz/companyDetail' } }, {
        default: _withCtx(() => [
          _createTextVNode(" 当前位置：" + _toDisplayString(_unref(company).name), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_breadcrumb_item, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(breack.value), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["separator-icon"]))
}
}

}